import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { uniq } from 'lodash-es';

import { ProjectsApiService } from '@shared/_services/project/projects-api.service';
import * as actions from './projects.actions';
import { loadAvatars } from '../users-store/users.actions';
import { selectProjectById } from '@shared/_root-store/projects-store/projects.selectors';
import { Store } from '@ngrx/store';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { MemberPermission, MemberRole, UpdateProjectDto } from '@shared/_models';
import {
  getPayloadForAddMember,
  getPayloadForChangeRoleToLead,
  getPayloadForChangeRoleToMember,
  getPayloadForChangeRoleToOwner,
  getPayloadForRemoveUserFromProject
} from '@shared/_root-store/projects-store/utils/get-payload-for-project-update';
import { isPreprocessedError } from '@app/domain/error-handling';
import { notifyAboutError } from '../app-store/app.actions';

@Injectable()
export class ProjectsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly projectsApiService: ProjectsApiService,
    private store: Store,
    private toastService: ToastService
  ) {}

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProjects),
      concatMap(({ workspaceId }) =>
        this.projectsApiService.getList(workspaceId).pipe(
          map(data => actions.loadProjectsSuccess({ data })),
          catchError(errorResponse => of(actions.loadProjectsFailure({ errorResponse })))
        )
      )
    )
  );

  loadAvatarsForProjectMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProjectsSuccess),
      map(({ data: projects }) => {
        const ids = uniq(
          projects.reduce((acc, project) => {
            return acc.concat(...project.leads.map(lead => lead.id), ...project.members.map(member => member.user.id), project.user_crt.id);
          }, [])
        );

        return loadAvatars({ ids });
      })
    )
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateProject),
      concatMap(({ projectId, changes, onSuccess }) =>
        this.projectsApiService.update(projectId, changes).pipe(
          tap(() => onSuccess?.()),
          map(data => actions.updateProjectSuccess({ data })),
          catchError(errorResponse => {
            if (isPreprocessedError(errorResponse?.error)) {
              return of(actions.updateProjectFailure({ errorResponse }));
            }

            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  addMemberToProject = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addMemberToProject),
      concatLatestFrom(action => this.store.select(selectProjectById(action.projectId))),
      concatMap(([action, project]) => {
        const payload = getPayloadForAddMember(action.userId, project);
        return this.projectsApiService.update(action.projectId, payload).pipe(
          map(data => actions.updateProjectSuccess({ data })),
          tap(() =>
            this.toastService.show('Member added successfully to project', {
              header: 'Member added',
              type: 'success'
            })
          )
        );
      })
    )
  );

  changeRole = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeRole),
      concatLatestFrom(action => this.store.select(selectProjectById(action.projectId))),
      concatMap(([action, project]) => {
        let payload: Partial<UpdateProjectDto>;
        switch (action.newRole) {
          case MemberRole.owner:
            payload = getPayloadForChangeRoleToOwner(action.userId, project);
            break;
          case MemberRole.lead:
            payload = getPayloadForChangeRoleToLead(action.userId, project);
            break;
          case MemberRole.editor:
            payload = getPayloadForChangeRoleToMember(action.userId, project, MemberPermission.WRITE);
            break;
          case MemberRole.reader:
            payload = getPayloadForChangeRoleToMember(action.userId, project, MemberPermission.READ);
        }
        return this.projectsApiService.update(action.projectId, payload).pipe(
          map(data => actions.updateProjectSuccess({ data })),
          tap(() =>
            this.toastService.show('Role changed successfully', {
              header: 'Role changed',
              type: 'success'
            })
          )
        );
      })
    )
  );

  removeUserFromProject = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.removeUserFromProject),
      concatLatestFrom(action => this.store.select(selectProjectById(action.projectId))),
      concatMap(([action, project]) => {
        const payload = getPayloadForRemoveUserFromProject(action.userId, project);
        return this.projectsApiService.update(action.projectId, payload).pipe(
          map(data => actions.updateProjectSuccess({ data })),
          tap(() =>
            this.toastService.show('User removed successfully from project', {
              header: 'User removed',
              type: 'success'
            })
          )
        );
      })
    )
  );
}
