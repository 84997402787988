import { WorkspaceDto, UserDto, WorkspaceMemberStatus } from '@shared/_models';

export function getInitialWorkspace(workspaces: WorkspaceDto[], currentUser?: UserDto, workspaceId?: string): WorkspaceDto {
  if (!workspaces?.length) return;

  if (workspaceId) {
    const workspace = workspaces.find(workspace => workspace.id === workspaceId);

    if (workspace) return workspace;
  }

  const getWorkspaces = (): WorkspaceDto[] => {
    if (!currentUser) return workspaces;

    return workspaces.filter(
      workspace => !workspace.members.some(member => member.user.id === currentUser.id && member.status === WorkspaceMemberStatus.PENDING)
    );
  };

  return workspaces.find(workspace => workspace.is_favourite) || getWorkspaces()?.[0];
}
