import { Component, ElementRef, Inject, Input, OnDestroy, TemplateRef, ViewChild, signal, WritableSignal, computed } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AsyncPipe, LowerCasePipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';

import { ConditionalPrefixPipe, EDITING_PREFIX, ADDING_NEW_PREFIX } from '@shared/pipes';
import { AppSettings } from '@shared/_configuration';
import {
  DevelopmentType,
  Field,
  ResearchObject,
  InstanceDto,
  ErrorMessageSection,
  UpdateArea,
  SectionType,
  ResearchObjectInstanceAddDto,
  SectionDto
} from '@shared/_models';
import { InstanceApiService } from '@shared/_services/instance';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { ItemsCounterComponent } from '@shared/_components/item/items-counter/items-counter.component';
import { ItemDetailsTableSectionComponent } from '@shared/_components/item/item-details/item-details-table-section/item-details-table-section.component';
import { ItemDetailsEditModalComponent } from '@shared/_components/item/item-details/item-details-edit-modal/item-details-edit-modal.component';
import { ItemDetailsRemoveRejectionComponent } from '@shared/_components/item/item-details/item-details-remove-rejection/item-details-remove-rejection.component';
import { ItemDetailsInfoModalComponent } from '@shared/_components/item/item-details/item-details-info-modal/item-details-info-modal.component';
import { ItemDetailsRemoveConfirmationComponent } from '@shared/_components/item/item-details/item-details-remove-confirmation/item-details-remove-confirmation.component';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { getInstanceViewData } from '@app/_research_objects/research-object/research-object-info/utils';
import { toFieldValueDto } from '@shared/dto-adapters/field';
import { ItemDetailsInstancesDropdownComponent } from '../item-details-instances-dropdown/item-details-instances-dropdown.component';
import { ResearchObjectComponentStore } from '@shared/_root-store/research-objects-store/research-object.component-store';
import { ItemDetailsFieldsBaseComponent } from '@shared/_components/item/item-details/item-details-fields-base/item-details-fields-base.component';
import { EmptyAreaContentComponent } from '@shared/_components/empty-area-content/empty-area-content.component';
import { getAreaName } from '@app/domain/shared';
import { selectSelectedProject } from '@app/shared/_root-store/projects-store/projects.selectors';

export enum InstanceViewMode {
  READ = 'READ',
  ADD = 'ADD',
  EDIT = 'EDIT'
}

@Component({
  selector: 'app-item-details-instances',
  standalone: true,
  imports: [
    AsyncPipe,
    LowerCasePipe,
    TitleCasePipe,
    NgClass,
    NgFor,
    NgIf,
    ItemDetailsSectionComponent,
    ItemsCounterComponent,
    ItemDetailsTableSectionComponent,
    ItemDetailsEditModalComponent,
    ItemDetailsRemoveRejectionComponent,
    ItemDetailsInfoModalComponent,
    ItemDetailsRemoveConfirmationComponent,
    ButtonComponent,
    ItemDetailsInstancesDropdownComponent,
    NgbModule,
    EmptyAreaContentComponent
  ],
  templateUrl: './item-details-instances.component.html',
  styleUrls: ['./item-details-instances.component.scss']
})
export class ItemDetailsInstancesComponent extends ItemDetailsFieldsBaseComponent implements OnDestroy {
  @Input() set researchObject(value: ResearchObject) {
    this.#onResearchObjectChange(value);
  }
  researchObjectSignal: WritableSignal<ResearchObject> = signal(null);
  @ViewChild('removeRejectionModal', { static: false })
  removeRejectionModal: ElementRef;
  @ViewChild('removeConfirmationModal', { static: false })
  removeConfirmationModal: ElementRef;

  activeArea = computed<boolean>(() => this.researchObjectSignal()?.template?.active_section_types?.includes(SectionType.INSTANCES));
  instanceViewMode = signal<InstanceViewMode>(InstanceViewMode.READ);
  activeInstance: InstanceDto;
  instancesViewData: Field[];
  errorMessage$ = new BehaviorSubject<ErrorMessageSection>({ errorResponse: false, section: null });
  destroy$ = new Subject<void>();
  section = computed<SectionDto>(() =>
    this.researchObjectSignal()?.template.sections.find(section => section.type === SectionType.INSTANCES)
  );
  #areaName = computed<string>(() => getAreaName(this.project(), DevelopmentType.researchObject)[SectionType.INSTANCES]);
  areaName = computed<string>(() => {
    const name = this.#areaName();

    return this.activeArea() ? name : this.#addModePrefix(name, this.instanceViewMode());
  });
  sectionName = computed<string>(() => {
    const name = this.activeArea() ? this.section()?.name : this.#areaName();

    return this.#addModePrefix(name, this.instanceViewMode());
  });
  addInstanceLabel = computed<string>(() => {
    const isAreaActive = this.researchObjectSignal()?.template.active_section_types?.includes(SectionType.INSTANCES);
    const name = isAreaActive ? this.section()?.name : this.#areaName();

    return `Add ${name}`;
  });
  pending: WritableSignal<boolean> = this.instanceApiService.pending;
  project = toSignal(this.store.select(selectSelectedProject));

  readonly DevelopmentType = DevelopmentType;
  readonly InstanceViewMode = InstanceViewMode;
  readonly SectionType = SectionType;
  readonly EDITING_PREFIX = EDITING_PREFIX;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly toastService: ToastService,
    private readonly instanceApiService: InstanceApiService,
    private readonly ngbModalService: NgbModal,
    private readonly conditionalPrefixPipe: ConditionalPrefixPipe,
    private readonly developmentItemService: DevelopmentItemService,
    private readonly researchObjectComponentStore: ResearchObjectComponentStore,
    private readonly store: Store
  ) {
    super();
    this.#subscribeToEditEvents();
  }

  onActiveInstanceChange(instance: InstanceDto) {
    this.activeInstance = instance;
    this.instancesViewData = getInstanceViewData(this.activeInstance, this.researchObjectSignal());
  }

  openModal(content: TemplateRef<string>) {
    this.ngbModalService.open(content, this.settings.MODAL_DEFAULT_CONFIG);
  }

  add() {
    if (!this.editable() || this.section().fields.length === 0) return;

    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    this.instanceViewMode.set(InstanceViewMode.ADD);
    this.developmentItemService.setEditActiveSectionName(this.#areaName());
    this.instancesViewData = getInstanceViewData(null, this.researchObjectSignal());
  }

  edit() {
    if (!this.editable()) return;

    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }

    this.instanceViewMode.set(InstanceViewMode.EDIT);
    this.developmentItemService.setEditActiveSectionName(this.#areaName());
    this.instancesViewData = getInstanceViewData(this.activeInstance, this.researchObjectSignal());
  }

  cancel() {
    this.instancesViewData = getInstanceViewData(this.activeInstance, this.researchObjectSignal());
    this.instanceViewMode.set(InstanceViewMode.READ);
    this.errorMessage$.next({ errorResponse: false, section: null });
  }

  save() {
    this.submitted.set(true);

    if (this.areErrors()) return;

    const dataToUpdate: ResearchObjectInstanceAddDto = {
      serial_number: this.instancesViewData[0].value || '',
      section_id: this.section().id,
      ro_id: this.researchObjectSignal().id,
      field_values: this.instancesViewData.slice(1).map(field => toFieldValueDto(field))
    };

    const addOrUpdateInstance$ =
      this.instanceViewMode() === InstanceViewMode.ADD
        ? this.instanceApiService.add(dataToUpdate)
        : this.instanceApiService.update(dataToUpdate, this.activeInstance.id);

    addOrUpdateInstance$
      .pipe(
        tap(instance => {
          this.activeInstance = instance;
          this.instancesViewData = getInstanceViewData(this.activeInstance, this.researchObjectSignal());
          this.researchObjectComponentStore.reloadResearchObject();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: err => {
          this.errorMessage$.next({ errorResponse: err as HttpErrorResponse, section: UpdateArea.INSTANCES });
        },
        complete: () => {
          this.instanceViewMode.set(InstanceViewMode.READ);
          this.errorMessage$.next({ errorResponse: false, section: null });
        }
      });
  }

  onDeleteBtnClick() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    const anyRelatedExperiments = this.researchObjectSignal().experiments.length > 0;
    const modal = anyRelatedExperiments ? this.removeRejectionModal : this.removeConfirmationModal;

    this.ngbModalService.open(modal, this.settings.MODAL_DEFAULT_CONFIG);
  }

  delete(modal: NgbModalRef) {
    this.instanceApiService
      .delete(this.activeInstance.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.researchObjectComponentStore.reloadResearchObject();
          this.researchObjectSignal.update(value => ({
            ...value,
            instances: value.instances.filter(instance => instance.id != this.activeInstance.id)
          }));
          this.activeInstance = this.researchObjectSignal().instances[0];
          modal.close();
          this.toastService.show('Selected ' + this.section()?.name + ' removed successfully', {
            header: this.section()?.name + ' removed',
            type: 'success',
            progressBar: true,
            toastDecayProgress: 0
          });
        },
        error: () => {
          this.toastService.show('Selected ' + this.section()?.name + ' cannot be removed because it is used in experiment', {
            header: 'Cannot remove ' + this.section()?.name,
            type: 'danger',
            delay: 6000,
            progressBar: true,
            toastDecayProgress: 0
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  #addModePrefix(value: string, instanceViewMode: InstanceViewMode): string {
    if (instanceViewMode === InstanceViewMode.ADD) {
      return this.conditionalPrefixPipe.transform(value, true, ADDING_NEW_PREFIX);
    }

    if (instanceViewMode === InstanceViewMode.EDIT) {
      return this.conditionalPrefixPipe.transform(value, true, EDITING_PREFIX);
    }

    return value;
  }

  #onResearchObjectChange(value: ResearchObject) {
    const isDifferentId = (!!value && isNil(this.researchObjectSignal())) || value?.id !== this.researchObjectSignal()?.id;
    this.researchObjectSignal.set(value);

    if (!isDifferentId) return;

    this.activeInstance = this.researchObjectSignal().instances[0];
    this.instancesViewData = getInstanceViewData(this.activeInstance, this.researchObjectSignal());
  }

  #subscribeToEditEvents() {
    this.developmentItemService.sectionEditCancelEvent$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.instanceViewMode() === InstanceViewMode.READ) return;

      this.cancel();
    });
    this.developmentItemService.sectionEditSaveEvent$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.instanceViewMode() === InstanceViewMode.READ) return;

      this.save();
    });
  }
}
