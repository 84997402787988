<div class="scrollable-container">
  <span class="scrollable-container__group mt-0">Team Leads</span>
  <div *ngFor="let data of leadsWithAvatars(); let i = index">
    <app-member-element
      [member]="data.lead"
      [avatar]="data.avatar"
      [index]="i"
      [projectProxy]="projectProxy()"
      [infoPopover]="{ title: 'ROLES IN PROJECT', content: leaderRolePopopver }"
      class="scrollable-container__group__items__item"
      (memberDelete)="onMemberDelete(data.lead.user, removeUserFromProjectModal)"
      (editRole)="onEditRole($event, data.lead.user, changeOwnerConfirmationModal)"
    ></app-member-element>

    <ng-template #leaderRolePopopver>
      @switch (data.lead.roleBeforeEdit) {
        @case (MemberRole.owner) {
          <app-role-description
            [role]="data.lead.roleBeforeEdit"
            roleDescription="Owner can edit project settings. Can manage users and permissions."
          ></app-role-description>
        }
        @case (MemberRole.lead) {
          <app-role-description
            [role]="data.lead.roleBeforeEdit"
            roleDescription="Lead can do most of the same as the project Owner."
            [unableTo]="['delete project', 'give other members the role of Lead']"
          ></app-role-description>
        }
      }
    </ng-template>
  </div>
  <span class="scrollable-container__group">Team Members: {{ membersWithAvatars()?.length }}</span>
  <div>
    <div *ngFor="let data of membersWithAvatars(); let i = index">
      <app-member-element
        [member]="data.member"
        [index]="i"
        [projectProxy]="projectProxy()"
        [avatar]="data.avatar"
        [infoPopover]="{ title: 'ROLES IN PROJECT', content: memberRolePopopver }"
        (memberDelete)="onMemberDelete(data.member.user, removeUserFromProjectModal)"
        (editRole)="onEditRole($event, data.member.user, changeOwnerConfirmationModal)"
        class="scrollable-container__group__items__item"
      ></app-member-element>

      <ng-template #memberRolePopopver>
        @switch (data.member.roleBeforeEdit) {
          @case (MemberRole.editor) {
            <app-role-description
              [role]="data.member.roleBeforeEdit"
              roleDescription="Editor can add and remove experiments, objects in the project. View and edit data in EXP/RO's details. He will not have
            access to the project options and category editing manager."
              [unableTo]="['access to projects options i.e. name, members list and roles', 'access to manage categories and their fields']"
            ></app-role-description>
          }
          @case (MemberRole.reader) {
            <app-role-description
              [role]="data.member.roleBeforeEdit"
              roleDescription="Reader can see the Experiments, Objects and read their data. Cannot edit anything in the project."
              [unableTo]="[
                'access to projects options etc. name, members list and roles',
                'access to manage categories and their fields',
                'add, edit, delete EXP/RO’s',
                'edit data in Experiments and Objects'
              ]"
            ></app-role-description>
          }
        }
      </ng-template>
    </div>
  </div>
</div>

<ng-template #removeUserFromProjectModal>
  <app-generic-modal
    [closeButton]="{ text: 'No, I want to keep him' }"
    [acceptButton]="{ text: 'Yes, remove him' }"
    [developmentType]="DevelopmentType.project"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="onCloseRemoveUserModal($event)"
  >
    <ng-template #headerTemplate>
      <app-modal-header-center imgSrc="toast_icon_warning.png" [header]="'Do you want to remove this member from this project?'">
      </app-modal-header-center>
      <div class="info-modal__body text-center">
        <span class="info-modal__body-content">
          If you remove this member, he will no longer have access to this project.
          <span class="content-highlight-blue">
            Experiments, objects and all data from this project will no longer be available to him.
          </span>
          <br />
        </span>
      </div>
    </ng-template>
  </app-generic-modal>
</ng-template>
<ng-template #changeOwnerConfirmationModal>
  <change-owner-confirmation-modal
    [newOwner]="selectedUser()"
    [developmentType]="DevelopmentType.project"
    [ownerRolePopover]="{ title: 'ROLES IN PROJECT', content: leadPopover }"
    (onClose)="onCloseChangeOwnerModal($event)"
  >
  </change-owner-confirmation-modal>
</ng-template>
<ng-template #leadPopover>
  <app-role-description
    role="Lead"
    roleDescription="Lead can do most of the same as the project Owner."
    [unableTo]="['delete project', 'give other members the role of Lead']"
  ></app-role-description>
</ng-template>
