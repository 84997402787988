import { Component, computed, Inject, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AppSettings } from '@app/shared/_configuration';
import { DevelopmentType, UserDto, MemberEdit, IUserWithRole, ProjectProxy } from '@shared/_models';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { MembersDropdownComponent } from '@app/_projects/members-elements/members-dropdown/members-dropdown.component';
import { MembersElementsComponent } from '@app/_projects/members-elements/members-elements.component';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { Store } from '@ngrx/store';
import { selectProjectById } from '@shared/_root-store/projects-store/projects.selectors';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs/operators';
import { getMembersData } from '@app/domain/project';
import { addMemberToProject } from '@shared/_root-store/projects-store/projects.actions';
import { UsersStoreService } from '@shared/_root-store/users-store/users-store.service';

@Component({
  selector: 'app-project-members-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    GenericModalComponent,
    MembersDropdownComponent,
    MembersElementsComponent,
    ModalHeaderLeftComponent,
    ButtonComponent
  ],
  templateUrl: './project-members-edit-modal.component.html',
  styleUrls: ['./project-members-edit-modal.component.scss']
})
export class ProjectMembersEditModalComponent {
  @Input() modalRef: NgbModalRef;
  projectMembers: IUserWithRole[] = [];
  readonly DevelopmentType = DevelopmentType;
  showMembersSearch = false;
  projectId = input<string>();
  project = toSignal(toObservable(this.projectId).pipe(switchMap(id => this.store.select(selectProjectById(id)))));
  members = computed(() => {
    if (this.project()) {
      return getMembersData(this.project()).map(member => this.#createMemberEdit(member));
    }
    return [];
  });
  currentUser = toSignal<UserDto>(this.usersStoreService.currentUser$);
  projectProxy = computed(() => {
    return new ProjectProxy(this.currentUser(), this.project());
  });
  header = computed(() => this.project()?.name);

  constructor(
    private usersStoreService: UsersStoreService,
    @Inject(AppSettings) public readonly settings: AppSettings,
    private store: Store
  ) {}

  close() {
    this.closeModal();
  }

  closeModal() {
    this.modalRef.close();
  }

  #createMemberEdit(member: IUserWithRole) {
    return {
      user: member.user,
      roleAfterEdit: member.role,
      roleBeforeEdit: member.role,
      isRemoved: false
    } as MemberEdit;
  }

  onMemberAdd(userId: string) {
    this.store.dispatch(addMemberToProject({ userId, projectId: this.project().id }));
  }
}
