import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { catchError, throwError, Observable } from 'rxjs';

import { ProjectsStoreService } from '@app/shared/_root-store/projects-store/projects-store.service';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { getShortGUID } from '@shared/_helpers';
import { DevelopmentType, FileDto, ImageDto } from '@shared/_models';
import { FileUploadCardComponent } from '@shared/_modules/file/file-upload-card/file-upload-card.component';
import { FileUploadLimitAcceptedExtensionsPipe } from '@shared/_modules/file/pipes/file-upload-limit-accepted-extensions.pipe';
import { FilesModalIconCSSClassPipe } from '@shared/_modules/file/pipes/files-modal-icon-css-class.pipe';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { ImageApiService } from '@shared/_services/image/image-api.service';
import { FileWithoutExtensionPipe, StartCasePipe } from '@shared/pipes';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';

import { FileCategory } from '../models/file-category';
import { FileUploadStatus } from '../models/file-upload-status';

@Component({
  selector: 'app-files-upload',
  standalone: true,
  imports: [
    CommonModule,
    FilesModalIconCSSClassPipe,
    FileWithoutExtensionPipe,
    StartCasePipe,
    FileUploadLimitAcceptedExtensionsPipe,
    DevelopmentTypeCssClassPipe,
    FileUploadCardComponent,
    ButtonComponent,
    NgbPopover
  ],
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesUploadComponent {
  @Input() fileCategory: FileCategory;
  @Input() developmentType: DevelopmentType;
  @Input() developmentItemId: string;

  // we manage updating RO/EX heigher, this flag is about those operations
  @Input() set pending(value: boolean) {
    this.#pending.set(value);
  }
  #pending = signal(false);
  selectedProject = this.projectsService.selectedProject;

  @Input() sectionName: string;

  @Output() closed = new EventEmitter<{ anyFileUpdated: boolean }>();
  @Output() showListOfAllImages = new EventEmitter<{ anyFileUpdated: boolean }>();
  @Output() uploadSuccess = new EventEmitter<FileDto | ImageDto>();
  @Output() batchUploadSuccess = new EventEmitter<FileDto[]>();
  @Output() imageDeleteSuccess = new EventEmitter<string>();
  @Output() shareFiles = new EventEmitter<void>();
  fileUploadStatuses = signal<FileUploadStatus[]>([]);
  anyUploadPending = computed<boolean>(() => this.fileUploadStatuses().some(status => status.uploadPending) || this.#pending());
  DevelopmentType = DevelopmentType;
  FileCategory = FileCategory;

  constructor(
    private fileApiService: FileApiService,
    private imageApiService: ImageApiService,
    private readonly projectsService: ProjectsStoreService
  ) {}

  onFileSelected($event): void {
    const newFilesToUpload = this.addFileUploadStatus($event.target.files);
    this.fileUploadStatuses.update(value => [...value, ...newFilesToUpload]);
  }

  dropHandler($event: DragEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    const items: DataTransferItemList = $event.dataTransfer.items;
    const droppedFiles = [];

    if (items) {
      for (let i = 0; i < items.length; i++) {
        droppedFiles.push(items[i].getAsFile());
      }

      const newFilesToUpload = this.addFileUploadStatus(droppedFiles);
      this.fileUploadStatuses.update(value => [...value, ...newFilesToUpload]);
    }
  }

  dragOverHandler($event: DragEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  dragEnterHandler($event: DragEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  dragLeaveHandler($event: DragEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  onUploadCancel(fileIndex: number): void {
    this.removeFileUploadStatus(fileIndex);
  }

  onCloseBtnClick(): void {
    this.closed.emit({ anyFileUpdated: this.fileUploadStatuses().length > 0 });
  }

  onUploadInProgress(fileIndex: number): void {
    this.fileUploadStatuses.update(value => value.map((item, index) => (index === fileIndex ? { ...item, uploadPending: true } : item)));
  }

  onUploadSuccess(fileIndex: number, $event: FileDto | ImageDto): void {
    if (this.fileCategory === FileCategory.images) {
      this.uploadSuccess.emit($event);
    }

    this.fileUploadStatuses.update(value => {
      const match = value[fileIndex];

      match.uploadPending = false;
      match.dto = $event;

      return [...value];
    });

    this.#handleDevelopmentItemUpdate();
  }

  onUploadFail(fileIndex: number) {
    this.fileUploadStatuses.update(value => value.filter((_, index) => index !== fileIndex));

    this.#handleDevelopmentItemUpdate();
  }

  onFileRemove(fileId: string, fileIndex: number): void {
    const deleteOperation: Observable<string | ImageDto> =
      this.fileCategory === FileCategory.files ? this.fileApiService.delete(fileId) : this.imageApiService.delete(fileId);

    this.fileUploadStatuses.update(value => value.map((item, index) => (index === fileIndex ? { ...item, uploadPending: true } : item)));
    deleteOperation
      .pipe(
        catchError(error => {
          this.removeFileUploadStatus(fileIndex);
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.fileCategory === FileCategory.images && this.imageDeleteSuccess.emit(fileId);
        this.removeFileUploadStatus(fileIndex, this.fileCategory === FileCategory.files);
      });
  }

  removeFileUploadStatus(fileIndex: number, updateDevObjectRequired = false): void {
    this.fileUploadStatuses.update(value => value.filter((_, index) => index !== fileIndex));

    if (updateDevObjectRequired) this.#handleDevelopmentItemUpdate();
  }

  onListOfAllImagesClick(): void {
    const anyFileUpdated = this.fileUploadStatuses().length > 0;
    this.showListOfAllImages.emit({ anyFileUpdated });
  }

  trackByGUID(_: number, fileUploadStatus: FileUploadStatus): string {
    return fileUploadStatus.guid;
  }

  onShareFiles() {
    this.shareFiles.emit();
  }

  private addFileUploadStatus(files: File[]): FileUploadStatus[] {
    return Array.from(files).map((file: File) => this.createFileUploadStatusFromFile(file));
  }

  private createFileUploadStatusFromFile(file: File): FileUploadStatus {
    const formData = new FormData();
    formData.append(this.fileCategory === FileCategory.files ? 'file' : 'image', file);
    const uploadHttpEvents$ =
      this.fileCategory === FileCategory.files
        ? this.fileApiService.upload(formData, { object_id: this.developmentItemId, development_type: this.developmentType })
        : this.imageApiService.upload(formData, { object_id: this.developmentItemId, development_type: this.developmentType });

    return {
      guid: getShortGUID(),
      fileToUpload: file,
      uploadPending: false,
      uploadHttpEvents$
    };
  }

  #handleDevelopmentItemUpdate() {
    if (this.anyUploadPending()) return;

    this.batchUploadSuccess.emit(
      this.fileUploadStatuses()
        .map(status => status.dto)
        .filter(Boolean) // just in case
    );
  }
}
