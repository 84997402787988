import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DevelopmentType, Experiment, RouteParam, CategoryTemplateDto, SectionType, AreaName } from '@shared/_models';
import { NavigationService } from '@app/shared/_services/navigation.service';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';
import { selectTemplates } from '@app/shared/_root-store/category-templates-store/category-templates.selectors';
import { selectSelectedProject } from '@app/shared/_root-store/projects-store/projects.selectors';
import { getAreaName } from '@app/domain/shared';

@Component({
  selector: 'app-experiment-table-item-details',
  templateUrl: './experiment-table-item-details.component.html',
  styleUrls: ['./experiment-table-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTableItemDetailsComponent {
  @Input() experiment: Experiment;
  @Output() showRelatedResearchObjectsChanged = new EventEmitter<boolean>();
  showRelatedResearchObjects = false;
  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  selectedProject$ = this.store.select(selectSelectedProject);
  areaName$: Observable<AreaName> = this.selectedProject$.pipe(map(project => getAreaName(project, DevelopmentType.researchObject)));

  readonly DevelopmentType = DevelopmentType;
  readonly SectionType = SectionType;

  templates$: Observable<CategoryTemplateDto[]> = this.store.select(selectTemplates);

  constructor(
    private readonly navigationService: NavigationService,
    private store: Store,
    private workspaceService: WorkspaceService
  ) {}

  switchShowRelatedResearchObjects(showRelatedResearchObjects: boolean) {
    this.showRelatedResearchObjects = showRelatedResearchObjects;
    this.showRelatedResearchObjectsChanged.emit(showRelatedResearchObjects);
  }

  goToResearchObjectInfo(researchObjectId: string, researchObjectCategoryId: string) {
    this.navigationService.goToResearchObjectInfo(
      this.workspaceService.currentWorkspaceId(),
      this.projectId(),
      researchObjectId,
      researchObjectCategoryId
    );
  }
}
