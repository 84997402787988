<div class="card-grid" [ngClass]="{ 'mb-n3': gridDataSignal()?.length > 0 }">
  <ng-container *ngFor="let data of gridDataSignal(); trackBy: trackByField; let i = index">
    <app-pepseq-form-group
      data-testid="card-item"
      *ngIf="data.field.parent && (data.field.parent.value || mode === FieldsViewMode.EDIT || mode === FieldsViewMode.TEMPLATE_PREVIEW)"
      [editMode]="mode === FieldsViewMode.EDIT"
      [complexField]="data.field"
      [errors]="pepseqErrors?.errors"
      [mode]="mode"
      class="card-grid__box card-grid__box--wide"
    ></app-pepseq-form-group>
    <!-- TODO: Add pipe instead ngClass contents -->
    <div
      class="card card-grid__box"
      *ngIf="!data.field.parent && (data.field.value || mode === FieldsViewMode.EDIT || mode === FieldsViewMode.TEMPLATE_PREVIEW)"
      [ngClass]="{
        'card--research-object': developmentType === DevelopmentType.researchObject,
        'card--experiment': developmentType === DevelopmentType.experiment,
        'card--single': gridDataSignal()?.length === 1,
        'card-date': data.field.type === DisplayFieldType.DATE,
        'invalid-border': errors()[i],
        'blue-border': mode === FieldsViewMode.EDIT,
        'card--research-object--edit': mode === FieldsViewMode.EDIT && developmentType === DevelopmentType.researchObject,
        'card--experiment--edit': mode === FieldsViewMode.EDIT && developmentType === DevelopmentType.experiment,
        'card-error-padding': errors()[i],
        'card-grid__box--wide': isBig(data.field),
        'card--full-width': data.field.type | isTextLong
      }"
    >
      <div class="card-content" data-testid="card-item">
        <div *ngIf="mode !== FieldsViewMode.EDIT" class="card__name" data-testid="field-value">{{ (data.field | label) || '-' }}</div>
        <span
          class="card__value"
          [ngClass]="{
            'card-smiles-sequence': data.field.type === 'smiles' || data.field.type === 'sequence',
            'card-date': data.field.type === 'date',
            'card-user': data.field.type === 'user'
          }"
        >
          <ng-container *ngIf="mode === FieldsViewMode.EDIT; else readonlyView">
            <app-dynamic-field
              [config]="data.field"
              [backendErrorMessage]="errors()[i]"
              (valueChange)="onNonComplexFieldValueChange($event.value, data.field)"
              (formTouched)="onFormTouched($event)"
              (errorsChange)="errorsChange.emit($event)"
            ></app-dynamic-field>
          </ng-container>
          <ng-template #readonlyView>
            <div class="d-flex justify-content-between align-items-center card--content">
              <app-content-with-copy-btn [valueToCopy]="data.field.value">
                <app-field-value-preview [field]="data.field"></app-field-value-preview>
              </app-content-with-copy-btn>
            </div>
          </ng-template>
        </span>
      </div>
    </div>
  </ng-container>
</div>
