import { computed, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { filter, map, tap, catchError } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

import { WorkspaceApiService } from '@shared/_services/workspace';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { WorkspaceDto } from '@shared/_models/workspace';
import * as actions from '@shared/_root-store/workspaces-store/workspaces.actions';
import { selectSelectedWorkspace } from '@shared/_root-store/workspaces-store/workspaces.selectors';
import { getInitialWorkspace } from '@shared/_root-store/workspaces-store/utils';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  currentWorkspace = toSignal(this.store.select(selectSelectedWorkspace));
  currentWorkspaceId = computed<string>(() => this.currentWorkspace()?.id);

  constructor(
    private store: Store,
    private workspaceApiService: WorkspaceApiService,
    private toastService: ToastService,
    private router: Router
  ) {}

  getInitialWorkspace(workspaceId?: string): Observable<WorkspaceDto> {
    return this.workspaceApiService.getList().pipe(
      tap(data => this.store.dispatch(actions.loadWorkspacesSuccess({ data }))),
      catchError(errorResponse => {
        this.store.dispatch(actions.loadWorkspacesFailure({ errorResponse }));

        // TODO: to prawdopodobnie do usunięcia (w tym momencie user jest już zalogowany więc nigdy nie dostanie 403)
        if (errorResponse.status === 403) {
          this.toastService.show('Please contact your administrator.', {
            header: 'You do not have access to requested workspace',
            type: 'danger'
          });

          return this.router.navigateByUrl('signin-form');
        }

        return throwError(() => errorResponse);
      }),
      filter(workspaces => typeof workspaces !== 'boolean'),
      map((workspaces: WorkspaceDto[]) => getInitialWorkspace(workspaces, null, workspaceId))
    );
  }
}
