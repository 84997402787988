import { ChangeDetectionStrategy, Component, OnInit, computed } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { chunk as _chunk } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

import {
  Field,
  ResearchObject,
  ImageMetaUpdateDataForEntity,
  SectionType,
  DisplayFieldType,
  DevelopmentItem,
  DevelopmentType
} from '@shared/_models';
import { ResearchObjectService } from '@shared/_services/research-object';
import { GridItemComponent } from '../grid-item/grid-item.component';
import { SPACE_CLASS_PREFIX } from '../grid-item/utils';
import { toSectionFields } from '@shared/dto-adapters/field';
import { CategoriesStoreService } from '@shared/_root-store/categories-store/categories-store.service';
import { updateImageMeta } from '@shared/_root-store/research-objects-store/research-objects.actions';
import { getInstanceSectionName } from '@app/domain/research-object';
import { selectSelectedProject, selectShowExperiments } from '@shared/_root-store/projects-store/projects.selectors';
import { comparePositions, getAreaName } from '@app/domain/shared';

@Component({
  selector: 'app-research-object-grid-item',
  templateUrl: './research-object-grid-item.component.html',
  styleUrls: ['./research-object-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchObjectGridItemComponent extends GridItemComponent<ResearchObject> implements OnInit {
  readonly categories$ = this.categoriesStoreService.categories$;
  firstInstanceNumber: string;
  instancesNumbers: string[];
  instanceSectionName = computed<string>(() => getInstanceSectionName(this.itemData(), this.selectedProject()?.section_type_names));
  parameters = computed<Field[]>(() =>
    toSectionFields(this.itemData(), { byType: { type: SectionType.PARAMETERS } })
      .filter(field => !!field.value)
      .sort(comparePositions)
  );
  parametersRows = computed<Field[][]>(() => _chunk(this.slicePipe.transform(this.parameters(), 0, 4), 2));
  currentCategoryId$ = this.route.params.pipe(map(params => params.categoryId));
  hideSamplesArea = computed<boolean>(() =>
    (<DevelopmentItem>this.itemData()).template?.hidden_section_types?.includes(SectionType.INSTANCES)
  );
  showExperiments = toSignal<boolean>(this.store.select(selectShowExperiments));
  sectionsSpace = computed<{ [key: string]: number }>(() => {
    const TOTAL_SPACE = 9;
    const FOOTER = 1;
    const userOrParams = this.currentCategoryHasAnyChild() ? 1 : this.hideParametersArea() ? 0 : this.parametersRows().length ? 3 : 2;
    const imageAndParamsAreBig = !this.hideImageArea() && !this.hideParametersArea() && this.parametersRows().length;
    const samples = this.hideSamplesArea() ? 0 : this.currentCategoryHasAnyChild() || imageAndParamsAreBig ? 1 : 2;
    const nameAndCategory = !this.hideImageArea()
      ? this.currentCategoryHasAnyChild()
        ? 2
        : 1
      : TOTAL_SPACE - FOOTER - samples - userOrParams;
    const image = TOTAL_SPACE - FOOTER - samples - userOrParams - nameAndCategory;

    return {
      image,
      nameAndCategory,
      userOrParams,
      samples
    };
  });
  firstSectionSpace = computed<number>(() => {
    if (!this.hideImageArea()) {
      return this.sectionsSpace().image;
    }

    return this.sectionsSpace().nameAndCategory;
  });
  imageSpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().image}`);
  nameAndCategorySpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().nameAndCategory}`);
  userOrParamsSpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().userOrParams}`);
  samplesSpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().samples}`);
  selectedProject = toSignal(this.store.select(selectSelectedProject));
  samplesAreaName = computed<string>(() => getAreaName(this.selectedProject(), DevelopmentType.researchObject)[SectionType.INSTANCES]);

  readonly DisplayFieldType = DisplayFieldType;

  constructor(
    private readonly researchObjectService: ResearchObjectService,
    private readonly categoriesStoreService: CategoriesStoreService,
    private readonly slicePipe: SlicePipe,
    private route: ActivatedRoute,
    store: Store
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.firstInstanceNumber = this.itemData().instances.length ? this.itemData().instances[0].serial_number : '';
    this.instancesNumbers = this.itemData().instances.map(instance => instance.serial_number);
  }

  toggleResearchObjectSelection($event, id: string): void {
    this.researchObjectService.toggleResearchObjectSelection($event, id);
  }

  isResearchObjectSelected(researchObjectId: string): Observable<boolean> {
    return this.researchObjectService.isResearchObjectSelected(researchObjectId);
  }

  onImageMetaChanged(data: ImageMetaUpdateDataForEntity) {
    this.store.dispatch(updateImageMeta({ data }));
  }
}
