import { Component, Inject, OnInit, ViewChild, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { AppSettings } from '@shared/_configuration';
import { GridItemComponent } from '../grid-item/grid-item.component';
import { ProjectsApiService } from '@shared/_services/project';
import { ProjectsStoreService } from '@shared/_root-store/projects-store/projects-store.service';
import { ACTION_TYPE } from '../../project/project-edit-add/project-edit-add.component';
import { NavigationService } from '@shared/_services/navigation.service';
import { ToastService } from '../../toast/toast.service';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { UsersStoreService } from '@shared/_root-store/users-store/users-store.service';
import { UserDto, IUserWithRole, ProjectDto, ProjectProxy, DevelopmentType } from '@shared/_models';
import { UserApiService } from '@shared/_services/user';
import { ResearchObjectsStoreService } from '@shared/_root-store/research-objects-store/research-objects-store.service';
import { getMembersData } from '@app/domain/project';
import { ModalType } from '@shared/_modules/project/utils';

@Component({
  selector: 'app-project-grid-item',
  templateUrl: './project-grid-item.component.html'
})
export class ProjectGridItemComponent extends GridItemComponent<ProjectDto> implements OnInit {
  projectOwner: string;
  usersWithHigherRights: string[];
  projectActionType: ACTION_TYPE;
  membersChangedData: IUserWithRole[] = null;
  projectProxy$ = this.usersStoreService.currentUser$.pipe(map(currentUser => new ProjectProxy(currentUser, this.itemData())));
  readonly ACTION_TYPE = ACTION_TYPE;
  readonly ModalType = ModalType;
  displayedProjectMembers: { blob$: Observable<Blob>; name: string; surname: string }[];
  projectDraft = signal<ProjectDto>(null);

  @ViewChild('editAddProjectModal') editAddProjectModal: NgbModalRef;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly activatedRoute: ActivatedRoute,
    private readonly projectsApiService: ProjectsApiService,
    private readonly projectsStoreService: ProjectsStoreService,
    private readonly ngbModal: NgbModal,
    private navigationService: NavigationService,
    private readonly toastService: ToastService,
    private readonly workspaceService: WorkspaceService,
    private readonly usersStoreService: UsersStoreService,
    private readonly userApiService: UserApiService,
    private readonly researchObjectsStoreService: ResearchObjectsStoreService,
    store: Store
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.projectOwner = this.getUserName(this.itemData().user_crt);
    this.usersWithHigherRights = this.getUsersWithHigherRights();

    this.displayedProjectMembers = this.itemData().members.map(member => ({
      blob$: this.userApiService.getUserImage(member.user.id),
      name: member.user.name,
      surname: member.user.surname
    }));
  }

  private getUsersWithHigherRights(): string[] {
    const usersWithHigherRights = this.itemData().leads.map(x => this.getUserName(x));
    usersWithHigherRights.unshift(this.projectOwner);
    return usersWithHigherRights;
  }

  private getUserName(user: UserDto) {
    return `${user.name} ${user.surname}`;
  }

  onTakeProjectAction(projectType: ACTION_TYPE, modal: NgbModalRef) {
    if (projectType === ACTION_TYPE.LEAVE) {
      this.onLeaveProject();
      modal.close();
    }
    if (projectType === ACTION_TYPE.JOIN) {
      this.onJoinProject();
      modal.close();
    }
  }

  onJoinProject() {
    this.projectsApiService.join(this.itemData().id).subscribe(() => {
      this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
      this.toastService.show('Request to join to project has been sent ', {
        header: 'Request send',
        type: 'success',
        progressBar: true,
        toastDecayProgress: 0
      });
    });
  }

  onLeaveProject() {
    this.projectsApiService.leave(this.itemData().id).subscribe(() => {
      this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
    });
  }

  openModal(content, type?: ACTION_TYPE, project?: ProjectDto): void {
    this.projectDraft.set(project ?? this.itemData());

    if (!this.membersChangedData) {
      this.membersChangedData = getMembersData(this.itemData());
    }

    this.ngbModal.open(content, this.settings.MODAL_DEFAULT_CONFIG);

    if (type) {
      this.projectActionType = type;
    }
  }

  onClose(modal: NgbModalRef): void {
    this.membersChangedData = null;
    modal.close();
  }

  onProjectCardClick() {
    this.researchObjectsStoreService.clearResearchObjectSelection();
    const workspaceId = this.activatedRoute.snapshot.paramMap.get('workspaceId');

    if (this.itemData().hidden_development_types.includes(DevelopmentType.researchObject)) {
      this.navigationService.goToExperimentsListWithParams(workspaceId, this.itemData().id, '-1');
      return;
    }

    this.navigationService.goToResearchObjectsListWithParams(workspaceId, this.itemData().id, '-1');
  }
}
