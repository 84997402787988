import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';

import { ButtonComponent } from '../button/button.component';
import { selectSelectedOrInitialWorkspace } from '@app/shared/_root-store/workspaces-store/workspaces.selectors';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [AsyncPipe, RouterLink, ButtonComponent],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  currentOrInitialWorkspace$ = this.store.select(selectSelectedOrInitialWorkspace);

  constructor(private readonly store: Store) {}
}
