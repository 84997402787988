import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, EMPTY, first, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { FileType, RouteParam } from '@shared/_models';
import { ResearchObjectFileApiService, ResearchObjectsApiService } from '@shared/_services/research-object';
import { ImageApiService } from '@shared/_services/image/image-api.service';
import * as actions from './research-objects.actions';
import { FileDownloadService } from '@shared/_services/file/';
import { ResearchObjectsStoreService } from './research-objects-store.service';
import { ItemsRemoveService } from '@app/shared/_services/item/items-remove.service';
import { ToastService } from '@app/shared/_modules/toast/toast.service';
import { RouterService } from '@app/shared/_services/router/router.service';
import { getPagesCount, toCurrentPage } from '@app/shared/_helpers';
import { selectCategoriesDictionary } from '../categories-store/categories.selectors';
import { toResearchObjects, toResearchObject } from '@app/shared/dto-adapters/research-object';
import { selectTemplatesDictionary } from '../category-templates-store/category-templates.selectors';
import { selectRelatedExperimentsCount } from './research-objects.selectors';
import { namePlural } from '@shared/_helpers/name-plural';
import { loadImagesContent } from '@shared/_root-store/images-store/images.actions';
import { ImageSize, toMainImages } from '@app/domain/image';
import { loadAvatars } from '@shared/_root-store/users-store/users.actions';
import { toAvatarIds } from '@app/domain/user';
import { toMessage } from '@app/domain/error-handling';
import { NavigationService } from '@shared/_services/navigation.service';
import { ProjectsStoreService } from '@shared/_root-store/projects-store/projects-store.service';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { getAttachParams } from '@app/main/utils';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectsEffects {
  #attachProjectId = getAttachParams(RouteParam.PROJECT_ID);

  constructor(
    private readonly actions$: Actions,
    private readonly researchObjectsApiService: ResearchObjectsApiService,
    private readonly researchObjectFileApiService: ResearchObjectFileApiService,
    private readonly imageApiService: ImageApiService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly researchObjectsStoreService: ResearchObjectsStoreService,
    private readonly itemsRemoveService: ItemsRemoveService,
    private readonly toastService: ToastService,
    private readonly routerService: RouterService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly projectsService: ProjectsStoreService,
    private readonly workspaceService: WorkspaceService
  ) {}

  loadResearchObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadResearchObjects),
      map(({ params }) => this.#attachProjectId(params)),
      concatMap(params =>
        this.researchObjectsApiService.getList(params).pipe(
          withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
          map(toResearchObjects),
          map(data => actions.loadResearchObjectsSuccess({ data })),
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 500) {
              this.router.navigateByUrl('app/error');
            }
            return of(actions.loadResearchObjectsFailure({ error }));
          })
        )
      )
    )
  );

  loadResearchObjectsAndStayOnCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadResearchObjectsAndStayOnCurrentPage),
      mergeMap(({ params }) =>
        this.researchObjectsApiService
          .getList({ ...params, page: 1 })
          .pipe(
            map(researchObjects => {
              const pageCount = getPagesCount(researchObjects.pagination);
              const currentPage = pageCount < params.page ? pageCount || 1 : params.page;

              return { params: { ...params, page: currentPage } };
            })
          )
          .pipe(
            switchMap(({ params }) => {
              return this.researchObjectsApiService.getList(params).pipe(
                withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
                map(toResearchObjects),
                map(researchObjectsForCurrentPage => {
                  this.routerService.navigateToCurrentRouteButWithDifferentQueryParams({
                    page: toCurrentPage(researchObjectsForCurrentPage.pagination)
                  });
                  return actions.loadResearchObjectsAndStayOnCurrentPageSuccess({
                    data: researchObjectsForCurrentPage
                  });
                })
              );
            })
          )
      )
    )
  );

  getSelectedResearchObjectsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAllResearchObjectsIds),
      concatMap(({ params }) =>
        this.researchObjectsApiService.getAllResearchObjectsIds(this.#attachProjectId(params)).pipe(
          map(data => actions.getAllResearchObjectsIdsSuccess({ data })),
          catchError(error => of(actions.getAllResearchObjectsIdsFailure({ error })))
        )
      )
    )
  );

  generateCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateCSV),
      concatMap(({ fileName, researchObjectsIds }) =>
        this.researchObjectFileApiService.generateCSV(researchObjectsIds).pipe(
          map((file: Blob) => actions.generateCSVSuccess({ fileName, file })),
          catchError(error => of(actions.generateCSVFailure({ error })))
        )
      )
    )
  );

  generateCSVSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.generateCSVSuccess),
        tap(({ fileName, file }) => {
          const fileData = {
            name: fileName,
            type: FileType.XLSX
          };
          // const msg = {
          //   header: 'Success',
          //   content: `Research Object file "${fileName}" was successfully generated.`
          // };

          // this.toastService.show(msg.content, {
          //   classname: 'bg-success text-light',
          //   delay: 7000,
          //   header: msg.header,
          // });

          this.fileDownloadService.downloadParametrized(fileData, file);
        })
      ),
    { dispatch: false }
  );

  generateCSVFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateCSVFailure),
      map(({ error }) => toMessage(error)),
      map(content =>
        notifyAboutError({
          notification: {
            content,
            header: 'Export failed'
          }
        })
      )
    )
  );

  generateMultipleCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateMultipleCSV),
      concatMap(({ fileName, roIdsGroupedByTemplateId }) =>
        this.researchObjectFileApiService.generateMultipleCSV(roIdsGroupedByTemplateId).pipe(
          map((file: Blob) => actions.generateMultipleCSVSuccess({ fileName, file })),
          catchError(error => of(actions.generateMultipleCSVFailure({ error })))
        )
      )
    )
  );

  generateMultipleCSVSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.generateMultipleCSVSuccess),
        tap(({ fileName, file }) => {
          const fileData = {
            name: fileName,
            type: FileType.ZIP
          };

          this.fileDownloadService.downloadParametrized(fileData, file);
        })
      ),
    { dispatch: false }
  );

  generateMultipleCSVFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateMultipleCSVFailure),
      map(({ error }) => toMessage(error)),
      map(content =>
        notifyAboutError({
          notification: {
            content,
            header: 'Export failed'
          }
        })
      )
    )
  );

  deleteResearchObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteResearchObject),
      mergeMap(({ researchObjectId, params }) =>
        this.researchObjectsApiService.delete(researchObjectId).pipe(
          tap(researchObjectId => this.researchObjectsStoreService.unselectResearchObject(researchObjectId)),
          map(researchObjectId => {
            return actions.deleteResearchObjectsSuccess({
              deletedResearchObjectsIds: [researchObjectId],
              deletedAllResearchObjects: true,
              params
            });
          }),
          catchError(error => of(actions.deleteResearchObjectFailure({ error })))
        )
      )
    )
  );

  deleteResearchObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteResearchObjects),
      mergeMap(({ researchObjectsIds, params, onDeleteFailed }) => {
        return this.researchObjectsApiService.deleteMultiple(researchObjectsIds).pipe(
          switchMap(response =>
            this.store.select(selectRelatedExperimentsCount(response.not_deleted)).pipe(
              first(),
              map(relatedExperimentsCount => ({ response, relatedExperimentsCount }))
            )
          ),
          map(({ response, relatedExperimentsCount }) => {
            this.itemsRemoveService.closeConfirmationModal$.next();
            if (response.deleted.length > 0) {
              this.researchObjectsStoreService.unselectResearchObjects(response.deleted);
              return actions.deleteResearchObjectsSuccess({
                deletedResearchObjectsIds: response.deleted,
                params,
                deletedAllResearchObjects: response.deleted.length == researchObjectsIds.length
              });
            }
            if (response.not_deleted.length > 0) {
              onDeleteFailed(response.not_deleted, relatedExperimentsCount);
              this.itemsRemoveService.openRejectionModal$.next();
              return actions.deleteResearchObjectsFailure({ showErrorMessage: false });
            }
          }),
          catchError(() => {
            this.itemsRemoveService.closeConfirmationModal$.next();
            return of(actions.deleteResearchObjectsFailure({ showErrorMessage: true }));
          })
        );
      })
    )
  );

  deleteResearchObjectsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteResearchObjectsSuccess),
        tap(({ deletedResearchObjectsIds, deletedAllResearchObjects, params }) => {
          const deleteCount = deletedResearchObjectsIds.length;
          if (!deleteCount) {
            return;
          }
          const toastMessage = deletedAllResearchObjects
            ? `Selected ${namePlural('object', deleteCount)} removed successfully`
            : 'Part of selected objects removed successfully';
          this.toastService.show(toastMessage, {
            header: `${namePlural('Object', deleteCount)} removed`,
            type: 'success'
          });
          this.researchObjectsStoreService.loadResearchObjectsAndStayOnCurrentPage(params);
        })
      ),
    { dispatch: false }
  );

  deleteResearchObjectsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteResearchObjectsFailure),
        tap(({ showErrorMessage }) => {
          if (!showErrorMessage) {
            return;
          }
          this.toastService.show('An error occured while removing objects', {
            header: 'Error',
            type: 'danger'
          });
        })
      ),
    { dispatch: false }
  );

  loadMainImagesForResearchObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadResearchObjectsSuccess, actions.loadResearchObjectsAndStayOnCurrentPageSuccess),
      map(({ data }) => loadImagesContent({ imagesMeta: toMainImages(data), size: ImageSize.SMALL }))
    )
  );

  loadAvatarsForResearchObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadResearchObjectsSuccess),
      map(({ data }) => loadAvatars({ ids: toAvatarIds(data) }))
    )
  );

  updateImageMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateImageMeta),
      switchMap(({ data }) =>
        this.imageApiService.updateMeta(data.updateData).pipe(
          map(imageMeta => actions.updateImageMetaSucceeded({ researchObjectId: data.entityId, imageMeta })),
          catchError(error => of(actions.updateImageMetaFailed({ error })))
        )
      )
    )
  );

  addResearchObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addResearchObject),
      switchMap(({ data, onSuccessAction, onFailAction }) =>
        this.researchObjectsApiService.add(data).pipe(
          withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
          map(toResearchObject),
          tap(researchObject => {
            this.navigationService.goToResearchObjectInfo(
              this.workspaceService.currentWorkspaceId(),
              this.projectsService.selectedProjectId(),
              researchObject.id,
              researchObject.template.category.id
            );

            onSuccessAction?.();
          }),
          map(researchObject => actions.addResearchObjectSuccess({ researchObject })),
          catchError(error => {
            onFailAction?.(error);

            return EMPTY;
          })
        )
      )
    )
  );
}
